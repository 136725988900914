import React from "react";
import {store} from "../../store";
import {userValid} from "../../actions";
import {subscribe} from "mqtt-react";


class LoggedWatcher extends React.Component{

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.props.mqtt.on('error', function () {
            // console.log("error")
            store.dispatch(userValid(false))
        })
        this.props.mqtt.on('connect', function () {
            // console.log("connected")
            store.dispatch(userValid(true))
        })
    }

    componentDidMount() {
        this.props.mqtt.on('error', function () {
            console.log("error")
            // store.dispatch(userValid(false))
        })
        this.props.mqtt.on('connect', function () {
            // console.log("connected")
            store.dispatch(userValid(true))
        })
    }

    render() {
        return (
            ""
        );
    }

}

export default subscribe({topic: '__'})(LoggedWatcher);
