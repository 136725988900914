import React from "react";
// nodejs library that concatenates classes
// import classNames from "classnames";
// nodejs library to set properties for components
// import PropTypes from "prop-types";
// @material-ui/core components
// import {makeStyles} from "@material-ui/core/styles";
// @material-ui/icons
import {withStyles} from "@material-ui/styles";
import Carousel from 'react-bootstrap/Carousel'
import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "react-device-detect";
// core components
import styles from "assets/jss/material-dashboard-react/components/cardStyle.js";
import CardHeader from "../Card/CardHeader";
// import CardIcon from "../Card/CardIcon";
// import Icon from "@material-ui/core/Icon";
import CardFooter from "../Card/CardFooter";
// import Danger from "../Typography/Danger";
// import Warning from "@material-ui/icons/Warning";
import Card from "../Card/Card";
import GridItem from "../Grid/GridItem";
import FlatCardBody from "../Card/FlatCardBody";
import Circle from "../Circle/Circle";
import Dateformat from "dateformat"
import CardBody from "../Card/CardBody";
import Button from "../CustomButtons/Button";
// import {CarouselItem} from "react-bootstrap";
import CustomInput from "../CustomInput/CustomInput";
import Hint from "react-portal-hint";
import "react-portal-hint/dist/default.css";
import "../../assets/css/default.css"
import {store} from "../../store";
import {saveQueue, userValid, userFetched} from "../../actions";
import {connect} from "react-redux";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DatePicker from "react-datepicker";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import moment from "moment";
import {Dropdown} from "react-bootstrap";
import DateRangeIcon from "@material-ui/icons/DateRange";

// import {Connector, publish} from 'mqtt-react';

// const useStyles = makeStyles(styles);


Dateformat.i18n = {
    dayNames: [
        'Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat',
        'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'
    ],
    monthNames: [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec',
        'stycznia', 'lutego', 'marca', 'kwietnia', 'maja', 'czerwca', 'lipca', 'sierpnia', 'września', 'października', 'listopada', 'grudnia'
    ],
    timeNames: [
        'a', 'p', 'am', 'pm', 'A', 'P', 'AM', 'PM'
    ]
};

function getStatusText(props, sensorId) {
    var ret = ""
    var lastRead = new Date().getTime() - Date.parse((props.sensors[sensorId + "_output"].lastSensorMessageTime != undefined) ? props.sensors[sensorId + "_output"].lastSensorMessageTime : props.sensors[sensorId + "_output"].lastReadTime2);
    if (lastRead <= 1000 * 60 * 60 * 24) {
        ret += "Status nadajnika: Połączony\n";
    } else {
        ret += "Status nadajnika: Brak połączenia\n";
    }
    if (props.sensors[props.sensors[sensorId + "_output"].receiver + "_lastPong"] && !props.sensors[props.sensors[sensorId + "_output"].receiver + "_connection"]) {
        var diff = new Date().getTime() - Date.parse(props.sensors[props.sensors[sensorId + "_output"].receiver + "_lastPong"].msg);
        if (diff <= 1000 * 60 * 30) {
            ret += "Status odbiornika: Połączony"
        } else {
            ret = "Status nadajnika: Brak połączenia\nStatus odbiornika: Brak połączenia"
        }
    } else if (props.sensors[props.sensors[sensorId + "_output"].receiver + "_connection"]) {
        var online = props.sensors[props.sensors[sensorId + "_output"].receiver + "_connection"].msg == "online";
        if (online) {
            ret += "Status odbiornika: Połączony"
        } else {
            ret = "Status nadajnika: Brak połączenia\nStatus odbiornika: Brak połączenia";
        }
    }
    return ret;
}

function isStatusChecked(props, sensorId) {
    var ret = ""
    var lastRead = new Date().getTime() - Date.parse(props.sensors[sensorId + "_output"].lastSensorMessageTime);
    if (lastRead > 1000 * 60 * 60 * 24) {
        return false;
    }
    if (props.sensors[props.sensors[sensorId + "_output"].receiver + "_lastPong"] && !props.sensors[props.sensors[sensorId + "_output"].receiver + "_connection"]) {
        var diff = new Date().getTime() - Date.parse(props.sensors[props.sensors[sensorId + "_output"].receiver + "_lastPong"].msg);
        if (diff > 1000 * 60 * 30) {
            return false;
        }
    } else if (props.sensors[props.sensors[sensorId + "_output"].receiver + "_connection"]) {
        return props.sensors[props.sensors[sensorId + "_output"].receiver + "_connection"].msg == "online";
    }
    return true;
}

class _Sensors extends React.Component/*(props)*/ {
    constructor(props, context) {
        super(props, context);
        // this.textInput = React.createRef();
        this.state = {
            index: [],
            direction: [],
            carouselItemCount: 2
        }
    }

    // const classes = useStyles();
    // let circle;

    getCircle(topic) {
        if (topic.endsWith("/output")) {
            return <Circle/>
        }
        return undefined;
    }

    isActive(sensorId, use) {
        if (this.props.sensors[sensorId + '_settings'] != undefined) {
            let settings = this.props.sensors[sensorId + '_settings'].msg;
            if (use == 'sewage' && (settings == undefined || settings.tankUse == undefined || settings.tankUse.use == undefined || settings.tankUse.use == 'sewage')) {
                return true;
            } else if (settings != undefined && settings.tankUse != undefined && settings.tankUse.use != undefined && settings.tankUse.use == 'rainwater' && use == 'rainwater') {
                return true
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    getTankUsage(sensorId) {
        if (this.props.sensors[sensorId + '_settings'] != undefined) {
            let settings = this.props.sensors[sensorId + '_settings'].msg;
            if ((settings == undefined || settings.tankUse == undefined || settings.tankUse.use == undefined || settings.tankUse.use == 'sewage')) {
                return "szambo";
            } else if (settings != undefined && settings.tankUse != undefined && settings.tankUse.use != undefined && settings.tankUse.use == 'rainwater') {
                return "deszczówka"
            }
        } else return "szambo"
    }

    getPercentage(sensor) {
        if (sensor.tankLevelPercent != undefined && !isNaN(sensor.tankLevelPercent)) {
            return sensor.tankLevelPercent;
        }
        // console.log(JSON.stringify(sensor));
        const number = ((sensor.maxLevel - sensor.lastRead) / (sensor.maxLevel - sensor.fullLevel * 100)) * 100;
        if (Number.isNaN(number)) {
            return 0;
        }
        return Math.round(Math.min(100, Math.max(0, number)));
    }

    getLastRead(sensor) {
        return Dateformat(Date.parse(sensor.lastReadTime2), "dd mmmm yyyy 'godz: ' HH:MM");
    }

    getLevel(sensor) {
        return Math.round(sensor.lastRead / 100);
    }

    isOpen(sensorProps) {
        // console.log(this.state != undefined && this.state.data != undefined && sensorProps.sensor == this.state.data.sensorEmailEdited);
        return this.state != undefined && this.state.data != undefined && sensorProps.sensor == this.state.data.sensorEmailEdited;
    }

    getSensorEmail = (sensor) => {
        if (this.props.sensors[sensor.sensor + "_settings"] != undefined) {
            let msg = this.props.sensors[sensor.sensor + "_email"].msg;
            if (msg != undefined && msg.email != undefined && msg.historySettings.date != undefined) {
                if (msg.historySettings.date instanceof Date) {
                    return msg.historySettings.date;
                } else {
                    console.log("Dateee=" + msg.historySettings.date);
                    const parse1 = moment(msg.historySettings.date, "yyyy-MM-dd'T'HH:mm:ssZ").toDate();
                    return parse1;
                }
            }
        }
        return Date.now();
    }

    handleClose = () => {

        this.setState({
            data: {
                sensorEmailEdited: null,
                emailToEdit: null
            }
        });
    }

    getDaysToFull(sensor) {
        const sensor1 = this.props.sensors[sensor.sensor + "_settings"];
        if (sensor1 != null && sensor1.msg != null && sensor1.msg.tankUse != null && sensor1.msg.tankUse.use == 'rainwater') {
            return "";
        }
        if (sensor.nextEmpty !== undefined && sensor.nextEmpty !== "") {

            let timeDifference = Math.abs(Date.now() - Date.parse(sensor.nextEmpty));
            let differentDays = Math.floor(timeDifference / (1000 * 3600 * 24));
            if (differentDays === 0) {
                return (<p style={{color: "red"}}>Dzisiaj powinieneś opróżnić zbiornik</p>)
            }
            if (differentDays === 1) {
                return (<p style={{color: "red"}}>Opróżnij zbiornik najpóźniej za 1 dzień.</p>);
            } else if (!Number.isNaN(differentDays)) {
                return (<p>Opróżnij zbiornik najpóźniej za {differentDays} dni.</p>)
            } else {
                return ""
            }
        }
    }

    save = (sensor) => {
        let topic = this.props.sensors[sensor + "_settings"].topic;
        let msg = this.props.sensors[sensor + "_settings"].msg;

        // console.log(JSON.stringify(msg))
        const {mqtt} = this.props;
        mqtt.publish(topic, JSON.stringify(msg), {retain: true, qos: 2});

    }

    toggleCarousel = (sensorDirection, sensor) => {
        let index = this.state.index;
        if (index[sensor] === undefined) {
            index[sensor] = 0;
        }
        let direction = this.state.direction;
        direction[sensor] = sensorDirection;

        const [min, max] = [0, this.state.carouselItemCount - 1]

        if (sensorDirection === 'next') {
            index[sensor]++

        } else if (sensorDirection === 'prev') {
            index[sensor]--
        }

        if (index[sensor] > max) {
            // at max, start from top
            index[sensor] = 0
        }

        if (index[sensor] < min) {
            // at min, start from max
            index[sensor] = max
        }
        this.setState({
            direction,
            index
        })
    }

    depthChanged(value, sensor1) {
        let paramName = value.target.id.split("_")[0];
        let maxLevelAuto = this.props.sensors[value.target.id.replace(paramName + "_", "") + "_output"].maxLevel;
        if (this.props.sensors[value.target.id.replace(paramName + "_", "") + "_settings"].msg["tankDepth"] == undefined) {
            this.props.sensors[value.target.id.replace(paramName + "_", "") + "_settings"].msg["tankDepth"] = {};
        }
        this.props.sensors[value.target.id.replace(paramName + "_", "") + "_settings"].msg.tankDepth.mode = value.target.value;
        this.setState({[value.target.id.replace(paramName + "_", "") + "_settings"]: this.props.sensors[value.target.id.replace(paramName + "_", "") + "_settings"].msg});
        console.log(value.target.value);
        document.getElementById(value.target.id).setAttribute("checked", "checked");
        return value.target.value;
    }

    autoSelected(sensorId, sensor1) {
        return this.props.sensors[sensorId + "_settings"] == undefined || this.props.sensors[sensorId + "_settings"].msg.tankDepth == undefined || this.props.sensors[sensorId + "_settings"].msg.tankDepth.mode != "manual";
    }

    getDepthValue(sensorId) {
        if (this.props.sensors[sensorId + "_settings"] != undefined && this.props.sensors[sensorId + "_settings"].msg.tankDepth != undefined && this.props.sensors[sensorId + "_settings"].msg.tankDepth.mode == "manual") {
            if (this.props.sensors[sensorId + "_settings"].msg.tankDepth.manualValue != undefined) {
                return this.props.sensors[sensorId + "_settings"].msg.tankDepth.manualValue;
            } else {
                if (this.props.sensors[sensorId + "_output"].maxLevel != undefined) {
                    return Math.round(this.props.sensors[sensorId + "_output"].maxLevel / 100);
                } else {
                    return "200";
                }
            }
        }
        return "";
    }

    getMailVerifiedValue(sensorId) {
        if (this.props.sensors[sensorId + "_email"] != undefined && this.props.sensors[sensorId + "_email"].msg.emailAddress != undefined && this.props.sensors[sensorId + "_email"].msg.emailAddress != "") {
            return this.props.sensors[sensorId + "_email"].msg.active ? "none" : "";
        }
        return "none";
    }

    isTankShapeDialogOpen(sensorProps) {
        return this.state != undefined && this.state.data != undefined && sensorProps.sensor == this.state.data.tankShapeEdited;
    }

    handleTankShapeDialogClose() {

    }

    getTankShape(settings) {
        if (settings != undefined && settings.msg.shape != undefined) {
            return settings.msg.shape.shape;
        } else {
            return "box";
        }
    }

    getNotificationMailValue(sensorId) {
        if (this.props.sensors[sensorId + "_email"] != undefined && this.props.sensors[sensorId + "_email"].msg.emailAddress != undefined) {
            return this.props.sensors[sensorId + "_email"].msg.emailAddress;
        }
        return "";
    }

    getTankShapeValue(sensorId) {
        if (this.props.sensors[sensorId + "_settings"] != undefined && this.props.sensors[sensorId + "_settings"].msg.shape != undefined) {
            const shape = this.props.sensors[sensorId + "_settings"].msg.shape.shape;
            if ("circle" == shape) {
                return "kula";
            } else if ("box" == shape) {
                return "kostka";
            }
        }
        return "kostka";
    }

    getNotificationMailValueToEdit(sensorId) {
        if (this.props.sensors[sensorId + "_email"] != undefined && this.props.sensors[sensorId + "_email"].msg.emailAddress != undefined) {
            return this.props.sensors[sensorId + "_email"].msg.emailAddress;
        }
        return "";
    }

    showMailNotificationEditScreen(sensorId) {
        this.setState({
            data: {
                sensorEmailEdited: sensorId,
                emailToEdit: this.props.sensors[sensorId + "_email"] != undefined && this.props.sensors[sensorId + "_email"].msg.emailAddress != undefined ? this.props.sensors[sensorId + "_email"].msg.emailAddress : ""
            }
        });
    }

    showShapeEditScreen(sensorId) {
        this.setState({
            data: {
                tankShapeEdited: sensorId,
                shapeToEdit: this.props.sensors[sensorId + "_settings"] != undefined && this.props.sensors[sensorId + "_settings"].msg.shape != undefined ? this.props.sensors[sensorId + "_settings"].msg.shape.shape : ""
            }
        });
    }

    depthValueChanging(value, sensor1) {
        let paramName = value.target.id.split("_")[0];
        if (this.props.sensors[value.target.id.replace(paramName + "_", "") + "_settings"].msg["tankDepth"] == undefined) {
            this.props.sensors[value.target.id.replace(paramName + "_", "") + "_settings"].msg["tankDepth"] = {};
        }
        let manualValue = value.target.value;
        this.props.sensors[value.target.id.replace(paramName + "_", "") + "_settings"].msg.tankDepth.manualValue = manualValue
        this.setState({[value.target.id.replace(paramName + "_", "") + "_settings"]: this.props.sensors[value.target.id.replace(paramName + "_", "") + "_settings"].msg});
        value.target.value = manualValue;
        return manualValue;
    }

    shapeValueChanging(value, sensor1) {
        let paramName = value.target.id.split("_")[0];
        if (paramName == "") {
            return;
        }

        if (this.props.sensors[value.target.id.replace(paramName + "_", "") + "_settings"].msg["shape"] == undefined) {
            this.props.sensors[value.target.id.replace(paramName + "_", "") + "_settings"].msg["shape"] = {};
        }
        let manualValue = value.target.value;
        console.log(manualValue);
        console.log(value.target.id.replace(paramName + "_", "") + "_settings");
        // this.props.sensors[value.target.id.replace(paramName + "_", "") + "_settings"].msg.shape.shape = manualValue
        // this.setState({[value.target.id.replace(paramName + "_", "") + "_settings"]: this.props.sensors[value.target.id.replace(paramName + "_", "") + "_settings"].msg});
        // value.target.value = manualValue;
        this.handleClose();
        return manualValue;
    }

    tankUseValueChanging(sensorId, settings, val) {
        if (this.props.sensors[sensorId + "_settings"].msg["tankUse"] == undefined) {
            this.props.sensors[sensorId + "_settings"].msg["tankUse"] = {};
        }
        console.log(sensorId + "_settings");
        this.props.sensors[sensorId + "_settings"].msg.tankUse.use = val
        this.setState({[sensorId + "_settings"]: this.props.sensors[sensorId + "_settings"].msg});

    }

    depthValueChanged(value, sensor1) {
        let paramName = value.target.id.split("_")[0];
        if (this.props.sensors[value.target.id.replace(paramName + "_", "") + "_settings"].msg["tankDepth"] == undefined) {
            this.props.sensors[value.target.id.replace(paramName + "_", "") + "_settings"].msg["tankDepth"] = {};
        }
        let manualValue = Math.min(450, Math.max(30, value.target.value));
        this.props.sensors[value.target.id.replace(paramName + "_", "") + "_settings"].msg.tankDepth.manualValue = manualValue
        this.setState({[value.target.id.replace(paramName + "_", "") + "_settings"]: this.props.sensors[value.target.id.replace(paramName + "_", "") + "_settings"].msg});
        value.target.value = manualValue;
        return manualValue;
    }

    handleSaveEmail(sensor) {
        const {mqtt} = this.props;
        let topic = this.props.sensors[sensor.sensor + "_email"].topic.replace("/get", "/add");
        mqtt.publish(topic, JSON.stringify({
            "emailAddress": this.state.data.emailToEdit,
            "active": false
        }), {retain: false, qos: 2});
        this.setState({data: {sensorHistoryEdited: ''}});
    }

    handleNameChange = (value) => {
        let paramName = value.target.id.split("_")[0];
        if (typeof this.props.sensors[value.target.id.replace(paramName + "_", "") + "_settings"].msg[paramName] == "number" && value.target.value != '') {
            this.props.sensors[value.target.id.replace(paramName + "_", "") + "_settings"].msg[paramName] = Number(value.target.value);
        } else {
            this.props.sensors[value.target.id.replace(paramName + "_", "") + "_settings"].msg[paramName] = value.target.value;
        }
        // console.log(paramName);
        // console.log(JSON.stringify(this.props.sensors[value.target.id.replace(paramName + "_", "") + "_settings"].msg[paramName]))
        this.setState({[value.target.id.replace(paramName + "_", "") + "_settings"]: this.props.sensors[value.target.id.replace(paramName + "_", "") + "_settings"].msg});
        return value.target.value;
    }

    handleEmailForNotificationChange = (value, sensorId) => {
        this.setState({
            data: {
                sensorEmailEdited: sensorId,
                emailToEdit: value.target.value
            }
        });
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        this.setState({
            data: {
                sensorEmailEdited: sensorId,
                emailToEdit: value.target.value,
                emailValid: re.test(String(value.target.value).toLowerCase()) || value.target.value.length == 0
            }
        });
        return value.target.value;
    }

    handleTankShapeChange = (value, sensorId) => {
        this.setState({
            data: {
                tankShapeEdited: sensorId,
                shapeToEdit: value.target.value
            }
        });

        return value.target.value;
    }


    getCard(sensorId, sensor, settings) {
        return <GridItem xs={12} sm={12} md={6} key={sensor.sensor}>
            <Card>
                <Carousel interval={null} indicators={null} next={null} touch={false}
                          activeIndex={this.state.index[sensor.sensor]} controls={false}
                          direction={this.state.direction[sensor.sensor]}>
                    <Carousel.Item>

                        <div className="notMoveable">
                            <CardHeader style={isMobile ? {padding: "0px"} : {}}>
                                <h3 style={isMobile ? {
                                    "textAlign": "center",
                                    margin: "0px",
                                    minHeight: "40px"
                                } : {"textAlign": "center"}}>
                                    {sensor.name}
                                    <div style={{position: "absolute", right: "0px", top: "0px"}}>
                                        <Hint content={getStatusText(this.props, sensorId)} place="left">
                                            <button style={{
                                                border: "none",
                                                background: "none",
                                                outline: "none",
                                                width: "45px"
                                            }}
                                                    onClick={() => this.showState(sensor.sensor)}><img
                                                src={isStatusChecked(this.props, sensorId) ? require("../../icons/check.svg") : require("../../icons/cross.svg")}
                                                style={{height: "30px"}}
                                                alt={"status"}/></button>
                                        </Hint>
                                        <button
                                            style={{border: "none", background: "none", outline: "none", width: "45px"}}
                                            onClick={() => this.toggleCarousel('prev', sensor.sensor)}><i
                                            className="fa fa-edit"></i></button>
                                    </div>
                                </h3>
                            </CardHeader>
                            <FlatCardBody style={{"textAlign": "center"}}>
                                <Circle percentage={this.getPercentage(sensor)} sensor={sensor.sensor}
                                        batteryLevel={sensor.batteryLevel} strength={sensor.rssi}/>
                            </FlatCardBody>
                            <CardFooter stats style={isMobile ? {"textAlign": "center"} : {"textAlign": "center"}}>

                                <h4 style={{width: "100%"}}>
                                    Masz jeszcze {this.getLevel(sensor)- sensor.fullLevel}cm wolnego miejsca (stan
                                    na {this.getLastRead(sensor)}).
                                    <br/>
                                    {this.getDaysToFull(sensor)}
                                </h4>


                            </CardFooter>
                        </div>
                    </Carousel.Item>
                    <Carousel.Item>
                        <CardHeader>
                            <h3 style={{"textAlign": "center"}}>
                                Parametry zbiornika
                            </h3>
                        </CardHeader>
                        <CardBody style={{"textAlign": "center"}}>
                            <CustomInput
                                labelText="Nazwa czujnika"
                                id={"name_" + sensor.sensor}
                                formControlProps={{
                                    fullWidth: true
                                }}
                                inputProps={{
                                    disabled: false,
                                    onChange: (value) => this.handleNameChange(value, this.props.sensors[sensorId + "_settings"]),
                                    value: this.props.sensors[sensorId + "_settings"] !== undefined ? this.props.sensors[sensorId + "_settings"].msg.name : sensor.sensor
                                }}
                            />
                            <CustomInput
                                labelText="Szambo pełne przy odległości płynu od czujnika [cm]"
                                id={"maxLevel_" + sensor.sensor}
                                formControlProps={{
                                    fullWidth: true
                                }}
                                inputProps={{
                                    disabled: false,
                                    type: "number",
                                    onChange: (value) => this.handleNameChange(value, this.props.sensors[sensorId + "_settings"]),
                                    value: (settings !== undefined) ? settings.msg.maxLevel : ""
                                }}
                            />
                            <CustomInput
                                labelText="Powiadomienie przy odległości cieczy od czujnika powyżej [cm]"
                                id={"maxLiquidLevelToNotify_" + sensor.sensor}
                                formControlProps={{
                                    fullWidth: true
                                }}
                                inputProps={{
                                    disabled: false,
                                    type: "number",
                                    onChange: (value) => this.handleNameChange(value, this.props.sensors[sensorId + "_settings"]),
                                    value: (settings !== undefined) ? settings.msg.maxLiquidLevelToNotify : ""
                                }}
                            />
                            <CustomInput
                                labelText="Na ile dni przed zapełnieniem zbiornika powiadamiać"
                                id={"maxDaysBeforeToNotify_" + sensor.sensor}
                                formControlProps={{
                                    fullWidth: true
                                }}
                                inputProps={{
                                    disabled: false,
                                    type: "number",
                                    onChange: (value) => this.handleNameChange(value, this.props.sensors[sensorId + "_settings"]),
                                    value: (settings !== undefined) ? settings.msg.maxDaysBeforeToNotify : ""
                                }}
                            />

                            <p className="makeStyles-formControl-184"
                               style={{"textAlign": "left", "paddingBottom": "0px"}}>
                                Głębokość zbiornika
                            </p>
                            <div className="form-inline" style={{"color": "black"}}>
                                <div className="form-check form-check-inline black-text" style={{"float": "left"}}>
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        id={"autoDepth_" + sensor.sensor}
                                        name={"tankDepth" + sensor.sensor}
                                        value="auto"
                                        checked={this.autoSelected(sensor.sensor, this.props.sensors[sensorId + "_settings"])}
                                        onChange={(value) => this.depthChanged(value, this.props.sensors[sensorId + "_settings"])}
                                    />
                                    <label className="form-check-label" htmlFor={"autoDepth_" + sensor.sensor}
                                           style={{"color": "black"}}>Automatycznie</label>
                                </div>


                                <div className="form-check form-check-inline" style={{"float": "left"}}>
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name={"tankDepth" + sensor.sensor}
                                        id={"manualDepth_" + sensor.sensor}
                                        value="manual"
                                        checked={!this.autoSelected(sensor.sensor, this.props.sensors[sensorId + "_settings"])}
                                        onChange={(value) => this.depthChanged(value, this.props.sensors[sensorId + "_settings"])}
                                    />
                                    <label htmlFor={"manualDepth_" + sensor.sensor}
                                           style={{"color": "black"}}>Ręcznie</label>
                                </div>
                                <div className="form-check form-check-inline" style={{"float": "left"}}>
                                    <div className="form-outline">
                                        <input type="number" id={"manualDepthValue_" + sensor.sensor}
                                               className="form-control"
                                               style={{"width": "80px"}}
                                               disabled={this.autoSelected(sensor.sensor, this.props.sensors[sensorId + "_settings"])}
                                               value={this.getDepthValue(sensor.sensor)}
                                               onChange={(value) => this.depthValueChanging(value, this.props.sensors[sensorId + "_settings"])}
                                               onBlur={(value) => this.depthValueChanged(value, this.props.sensors[sensorId + "_settings"])}
                                        />
                                    </div>
                                    &nbsp;cm
                                </div>
                            </div>

                            <div className="form-check form-check-inline" style={{"width": "100%"}}>
                                <CustomInput
                                    labelText="Adres e-mail do powiadomień"
                                    id={"emailAddressForNotification_" + sensor.sensor}
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    style={{"width": "100%", "float": "left"}}
                                    inputProps={{
                                        disabled: false,
                                        editable: false,
                                        type: "email",
                                        onChange: (value) => this.handleEmailForNotificationChange(value, sensorId),
                                        value: this.getNotificationMailValue(sensorId)
                                    }}

                                />

                                <button
                                    style={{
                                        border: "none",
                                        background: "none",
                                        outline: "none",
                                        width: "45px",
                                        "font-size": "25px",
                                        "margin": "27px 0 0 0"
                                    }}
                                    onClick={() => this.showMailNotificationEditScreen(sensorId)}><i
                                    className="fa fa-edit"></i></button>

                            </div>
                            <div className="form-check form-check-inline" style={{"width": "100%"}}>
                                <CustomInput
                                    labelText="Kształt zbiornika"
                                    id={"tankShape_" + sensor.sensor}
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    style={{"width": "100%", "float": "left"}}
                                    inputProps={{
                                        disabled: false,
                                        editable: false,
                                        type: "email",
                                        onChange: (value) => this.handleTankShapeChange(value, sensorId),
                                        value: this.getTankShapeValue(sensorId)
                                    }}

                                />

                                <button
                                    style={{
                                        border: "none",
                                        background: "none",
                                        outline: "none",
                                        width: "45px",
                                        "font-size": "25px",
                                        "margin": "27px 0 0 0"
                                    }}
                                    onClick={() => this.showShapeEditScreen(sensorId)}><i
                                    className="fa fa-edit"></i></button>

                            </div>
                            <div className="form-check form-check-inline" style={{"width": "100%"}}>
                                <CustomInput
                                    labelText="Przeznaczenie zbiornika"
                                    id={"tankShape_" + sensor.sensor}
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    style={{"width": "100%", "float": "left"}}
                                    inputProps={{
                                        disabled: false,
                                        editable: false,
                                        type: "email",
                                        onChange: (value) => this.handleTankShapeChange(value, sensorId),
                                        value: this.getTankUsage(sensorId)
                                    }}

                                />
                                <div
                                    style={{
                                        border: "none",
                                        background: "none",
                                        outline: "none",
                                        width: "45px",
                                        "font-size": "25px",
                                        "margin": "27px 0 0 0"
                                    }}
                                >
                                    <Dropdown>
                                        <Dropdown.Toggle variant="primary" id="dropdown-basic">
                                            <i style={{
                                                border: "none",
                                                background: "none",
                                                color: "black",

                                                "font-size": "25px",
                                                "margin": "0 0 0 0"
                                            }}
                                               className="fa fa-edit"></i>
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            <Dropdown.Item
                                                onClick={() => {
                                                    this.tankUseValueChanging(sensorId, settings, 'sewage')
                                                }}
                                                active={this.isActive(sensorId, 'sewage')}>
                                                Szambo
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                                onClick={() => {
                                                    this.tankUseValueChanging(sensorId, settings, 'rainwater')
                                                }}
                                                active={this.isActive(sensorId, 'rainwater')}>
                                                Deszczówka
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </div>
                            <div
                                style={{"color": "red", "display": this.getMailVerifiedValue(sensorId)}}>Ten adres
                                e-mail jest jeszcze nieaktywny. W swojej skrzynce pocztowej znajdziejsz maila
                                aktywacyjnego. Dopiero po aktywacji, na powyższy adres e-mail będą przychodziły
                                notyfikacje.
                            </div>


                        </CardBody>
                        <CardFooter stats style={{"textAlign": "center"}}>
                            <Button color="primary" onClick={() => this.toggleCarousel('prev', sensor.sensor)}>
                                Anuluj
                            </Button>
                            <Button disabled={!this.saveEnabled(sensor.sensor)} color="primary" enabled="false"
                                    onClick={() => {
                                        this.save(sensor.sensor);
                                        this.toggleCarousel('prev', sensor.sensor)
                                    }}>
                                Zapisz
                            </Button>
                        </CardFooter>
                    </Carousel.Item>
                </Carousel>
                <Dialog open={this.isOpen(sensor)} onClose={this.handleClose}
                        aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Edycja adresu e-mail do powiadomień</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Wpisz adres e-mail, który będzie użyty do komunikacji powiadomień. Na ten adres będzie
                            wysłany mail weryfikacyjny
                        </DialogContentText>
                        <CustomInput
                            labelText="Adres e-mail do powiadomień"
                            id={"emailAddressForNotification_" + sensor.sensor}
                            formControlProps={{
                                fullWidth: true
                            }}
                            inputProps={{
                                editable: true,
                                disabled: false,
                                type: "email",
                                onChange: (value) => this.handleEmailForNotificationChange(value, sensorId),
                                value: this.state.data != undefined && this.state.data.emailToEdit != undefined ? this.state.data.emailToEdit : ""
                            }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose} color="primary">
                            Anuluj
                        </Button>
                        <Button
                            disabled={this.state.data != undefined ? !this.state.data.emailValid : true}
                            onClick={() => {
                                this.handleSaveEmail(sensor)
                            }} color="primary">
                            Zapisz
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog open={this.isTankShapeDialogOpen(sensor)} onClose={this.handleTankShapeDialogClose}
                        aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Wybór kształtu zbiornika.</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Wybierz, jaki kształt ma Twój zbiornik. Wpłynie to na poprawność wyświetlania danych
                            predykcyjnych.
                        </DialogContentText>
                        <label>
                            <input
                                id={"shapeSquare_" + sensorId}
                                type="radio" class="custom-radio" name="test" value="box"
                                checked={'box' == this.getTankShape(this.props.sensors[sensorId + "_settings"])}
                                onChange={(value) => this.shapeValueChanging(value, this.props.sensors[sensorId + "_settings"])}/>
                            <div>
                                <img id={"shapeSquare_" + sensor.sensor} src={require("../../icons/square_tank.svg")}
                                     style={{height: "100px", "marginRight": "20px"}}/>
                                Kostka
                            </div>
                        </label>

                        <label>
                            <input
                                id={"shapeCircle_" + sensorId}
                                type="radio" class="custom-radio" name="test" value="circle"
                                checked={'circle' == this.getTankShape(this.props.sensors[sensorId + "_settings"])}
                                onChange={(value) => this.shapeValueChanging(value, this.props.sensors[sensorId + "_settings"])}/>
                            <div>
                                <img src={require("../../icons/circle_tank.svg")}
                                     style={{height: "100px", "marginRight": "20px"}}/>
                                Kula
                            </div>
                        </label>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose} color="primary">
                            Anuluj
                        </Button>
                    </DialogActions>
                </Dialog>
            </Card>

        </GridItem>
    }

    saveEnabled(sensorId) {
        return this.props.sensors[sensorId + '_settings'] != undefined
            && this.props.sensors[sensorId + '_settings'].msg != undefined
            && this.props.sensors[sensorId + '_settings'].msg.maxLevel != ''
            && this.props.sensors[sensorId + '_settings'].msg.maxLiquidLevelToNotify != ''
            && this.props.sensors[sensorId + '_settings'].msg.maxDaysBeforeToNotify != '';
    }

    render() {
        // return ""
        if (this.props.sensors === undefined) {
            return ""
        } else {
            return Object.entries(this.props.sensors).filter(function (t, m) {
                return t[0].endsWith("output")
            }).map((t, message) => this.getCard(t[0].replace("_output", ""), t[1], this.props.sensors[t[0].replace("_output", "_settings")]))
        }
    }


}

const mapStateToProps = (state) => {
    return {
        username: state.user.username,
        password: state.user.password,
        valid: state.userValid.valid,
        queue: state.queue
    }
};
const mapDispatchToProps = {userFetched: userFetched, userValid: userValid}; // (2)

const Sensors = connect(mapStateToProps, mapDispatchToProps)(_Sensors); // (3)

export default withStyles(styles)(Sensors);

