import React from "react";
// nodejs library that concatenates classes
// import classNames from "classnames";
// nodejs library to set properties for components
// import PropTypes from "prop-types";
// @material-ui/core components
// import {makeStyles} from "@material-ui/core/styles";
// @material-ui/icons

// core components
import styles from "assets/jss/material-dashboard-react/components/cardStyle.js";
import CardHeader from "../Card/CardHeader";
import DateRangeIcon from '@material-ui/icons/DateRange';
// import CardIcon from "../Card/CardIcon";
// import Icon from "@material-ui/core/Icon";
import CardFooter from "../Card/CardFooter";
// import Danger from "../Typography/Danger";
// import Warning from "@material-ui/icons/Warning";
import Card from "../Card/Card";
import GridItem from "../Grid/GridItem";
import CardBody from "../Card/CardBody";
// import Circle from "../Circle/Circle";
import Dateformat from "dateformat"
import Chart from "../Chart/Chart";
import {withStyles} from "@material-ui/styles";
import DatePicker from "react-datepicker";
import Toggle from 'react-toggle'
import parseFromTimeZone from 'date-fns-timezone'
import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
} from "react-device-detect";
import Hint from "react-portal-hint";
import {Dropdown} from "react-bootstrap";
import {subscribe} from "mqtt-react";
import _Sensors from "../Sensors/Sensors";
import Dialog from "@material-ui/core/Dialog";
import {store} from "../../store";
import {saveQueue} from "../../actions";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import "./styles.css"


Date.prototype.toJSON = function () {
    return moment(this).format();
}

Dateformat.i18n = {
    dayNames: [
        'Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat',
        'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'
    ],
    monthNames: [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec',
        'stycznia', 'lutego', 'marca', 'kwietnia', 'maja', 'czerwca', 'lipca', 'sierpnia', 'września', 'października', 'listopada', 'grudnia'
    ],
    timeNames: [
        'a', 'p', 'am', 'pm', 'A', 'P', 'AM', 'PM'
    ]
};

class SensorHistory extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    getCard(sensor, sensorProps, history) {
        if (sensor.endsWith("_output") && history !== undefined) {

            // return m.message.map((item) => {
            return <GridItem xs={12} sm={12} md={12} key={"gi_" + sensor}>
                <Card>
                    <CardHeader style={isMobile ? {padding: "0px"} : {}}>
                        <h3 style={isMobile ? {"textAlign": "center", margin: "0px"} : {"textAlign": "center"}}>
                            {sensorProps.name}
                            <div style={{position: "absolute", right: "0px", top: "0px"}}>
                                <div style={{float: "left", "margin-top": "10px"}}>
                                    <label htmlFor='cheese-status' style={{display: "flex", "align-items": "center", "margin-right": "15px"}}>
                                <Toggle
                                    id='cheese-status'
                                    defaultChecked={this.getToggleEnabled(sensorProps)}
                                    className='aquiloToggle'
                                    onChange={()=>{this.handleBatteryHistoryChange(sensorProps)}} />
                                Bateria    </label>
                                </div><div style={{float: "right"}}>
                                <Dropdown>
                                    <Dropdown.Toggle variant="primary" id="dropdown-basic">
                                        <DateRangeIcon/>
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <Dropdown.Item
                                            onClick={() => {
                                                this.changeHistoryRange(sensorProps, 1, "DAY")
                                            }}
                                            active={this.isActive(sensorProps, "DAY")}>
                                            Ostatni dzień
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                            onClick={() => {
                                                this.changeHistoryRange(sensorProps, 1, "WEEK")
                                            }}
                                            active={this.isActive(sensorProps, "WEEK")}>
                                            Ostatni tydzień
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                            onClick={() => {
                                                this.changeHistoryRange(sensorProps, 2, "TWO_WEEKS")
                                            }}
                                            active={this.isActive(sensorProps, "TWO_+WEEKS")}>
                                            Ostatnie 2 tygodnie
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                            onClick={() => {
                                                this.changeHistoryRange(sensorProps, 1, "MONTH")
                                            }}
                                            active={this.isActive(sensorProps, "MONTH")}>
                                            Ostatni miesiąc
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                            onClick={() => {
                                                this.handleOpen(sensorProps)
                                            }}
                                            active={this.isActive(sensorProps, "DATE")}>
                                            Od daty...
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>

                            </div>
                        </h3>
                    </CardHeader>
                    <CardBody style={{padding: "0px"}}>
                        <Chart sensor={sensorProps} data={history} battery={this.state.data} ref={{"current": sensor}}/>
                    </CardBody>
                    <Dialog open={this.isOpen(sensorProps)} onClose={this.handleClose}
                            aria-labelledby="form-dialog-title">
                        <DialogTitle id="form-dialog-title">Wybór daty początkowej zakresu</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Wybierz datę, która będzie początkiem zakresu danych przedstawianych na wykresie.
                            </DialogContentText>
                            <DatePicker selected={this.state.date}
                                        dateFormat="yyyy-MM-dd"
                                        onChange={date => this.setStartDate(date, sensorProps)}
                                        name={"dp_" + sensorProps.sensorProps}/>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.handleClose} color="primary">
                                Anuluj
                            </Button>
                            <Button onClick={() => {
                                this.handleSave(sensorProps)
                            }} color="primary">
                                Zapisz
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Card>
            </GridItem>
        } else {
            return null;
        }
    }

    getToggleEnabled(sensorProps) {
        return ('true' == localStorage.getItem("batteryHistoryToggle_" + sensorProps.sensor));
    }

    isActive = (sensor, mode)=>{
        if(this.props.sensors[sensor.sensor + "_settings"] != undefined) {
            let msg = this.props.sensors[sensor.sensor + "_settings"].msg;
            if (msg.historySettings != undefined && msg.historySettings.periodUnit == mode) {
                return true
            }
        }
        return false;
    }
    isOpen(sensorProps) {
        return this.state != undefined && this.state.data != undefined && sensorProps.sensor == this.state.data.sensorHistoryEdited;
    }

    handleClose = () => {
        this.setState({data: {sensorHistoryEdited: ''}});
    }


    handleSave = (sensor) => {
        this.setState({data: {sensorHistoryEdited: ''}});
        let topic = this.props.sensors[sensor.sensor + "_settings"].topic;
        let msg = this.props.sensors[sensor.sensor + "_settings"].msg;
        if (msg.historySettings == undefined) {
            msg.historySettings = {};
        }
        const {mqtt} = this.props;
        console.log(JSON.stringify(msg));
        console.log(topic);
        mqtt.publish(topic, JSON.stringify(msg), {retain: true, qos: 2});
    }

    render() {
        if (this.props.sensors === undefined) {
            return ""
        } else {
            return (
                Object.entries(this.props.sensors).map((t, message) =>
                    this.getCard(t[0], t[1], this.props.sensors[t[0].split("_")[0] + "_history"]))
            )
        }
    }

    changeHistoryRange(sensor, number, time) {
        let topic = this.props.sensors[sensor.sensor + "_settings"].topic;
        let msg = this.props.sensors[sensor.sensor + "_settings"].msg;
        if (msg.historySettings == undefined) {
            msg.historySettings = {};
        }
        msg.historySettings.number = number;
        msg.historySettings.periodUnit = time;
        // console.log(JSON.stringify(msg))
        const {mqtt} = this.props;
        // console.log(JSON.stringify(msg));
        // console.log(topic);
        mqtt.publish(topic, JSON.stringify(msg), {retain: true, qos: 2});
    }

    getStartDate = (sensor) => {
        if (this.props.sensors[sensor.sensor + "_settings"] != undefined) {
            let msg = this.props.sensors[sensor.sensor + "_settings"].msg;
            if (msg != undefined && msg.historySettings != undefined && msg.historySettings.date != undefined) {
                if (msg.historySettings.date instanceof Date) {
                    return msg.historySettings.date;
                } else {
                    console.log("Dateee=" + msg.historySettings.date);
                    const parse1 = moment(msg.historySettings.date, "yyyy-MM-dd'T'HH:mm:ssZ").toDate();
                    return parse1;
                }
            }
        }
        return Date.now();
    }

    handleOpen = (sensorProps) => {
        this.setState({data: {sensorHistoryEdited: sensorProps.sensor}});
        this.setState({date: this.getStartDate(sensorProps.sensor)});
    }

    setStartDate = (date, sensor) => {
        let msg = this.props.sensors[sensor.sensor + "_settings"].msg;
        if (msg.historySettings == undefined) {
            msg.historySettings = {};
        }
        this.setState({date: date});
        msg.historySettings.date = date;
        msg.historySettings.number = 1;
        msg.historySettings.periodUnit = "DATE";
        this.setState({data: {sensorHistoryEdited: sensor.sensor}});
    }

    handleBatteryHistoryChange = (sensorProps) => {
        console.log(sensorProps.sensor)
        let batteryToggleState = 'true' == localStorage.getItem("batteryHistoryToggle_" + sensorProps.sensor);
        batteryToggleState = !batteryToggleState;
        localStorage.setItem("batteryHistoryToggle_" + sensorProps.sensor, batteryToggleState);
            // console.log(JSON.stringify(sensorProps))
        let data = {};
        data["batteryHistoryToggle_" + sensorProps.sensor] = batteryToggleState;
        this.setState({data: data});
    }

    getBatteryVisible = (sensorProps)  =>{

        return this.state.data != undefined && this.state.data["batteryHistoryToggle_" + sensorProps.sensor] == 'true';
    }
}

export default withStyles(styles)(subscribe({topic: '__'})(SensorHistory));

