import React from "react";
// react plugin for creating charts
// @material-ui/core
// @material-ui/icons
// core components
// import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
// import Card from "components/Card/Card.js";
// import CardHeader from "components/Card/CardHeader.js";
// import CardIcon from "components/Card/CardIcon.js";
// import CardBody from "components/Card/CardBody.js";
// import CardFooter from "components/Card/CardFooter.js";
import _Sensors from "components/Sensors/Sensors.js";
import { subscribe} from 'mqtt-react';
import {connect} from 'react-redux'
// import Button from "components/CustomButtons/Button.js";
// import { contactsFetched } from "./actions";
import {withStyles} from '@material-ui/styles';
// import preval from 'preval.macro'

import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";
// import {store} from "../../store";
import {saveQueue, userValid, userFetched} from "../../actions";
// import CustomInput from "../../components/CustomInput/CustomInput";
// import { loadState, saveState } from '../lib/localStorage'
// import {Button} from "@material-ui/core";

// const
//
//     useStyles = makeStyles(styles);


const parse = function (message) {
    try {
        var item = JSON.parse(message);
        return item;
    } catch (e) {
        return message.toString();
    }
}

// const _toConsumableArray = function (arr) {
//     if (Array.isArray(arr)) {
//         for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
//             arr2[i] = arr[i];
//         }
//         return arr2;
//     } else {
//         return Array.from(arr);
//     }
// }

let Sensors = subscribe({topic: '__'})(_Sensors);
// const useStyles = makeStyles(styles);
// const classes = useStyles();


// const store = createStore(combineReducers);

class _Dashboard extends React.Component {

    constructor(props, context) {

        super(props, context);
        this.state = {sensors : {}};
    }

//
    username;
    password;
    full_name;
    queue;

    // saveCredentials() {
    //     store.dispatch(userFetched(this.username, this.password))
    //     // this.state.username = this.username;
    //     // this.props.password = this.password;
    //
    // }

    render() {
        // const {classes} = this.props;
        if (this.props.username !== undefined) {
            return (
                    <GridContainer>
                        <Sensors sensors={this.props.queue.queue}/>
                    </GridContainer>
            )
        }
    }
}

const mapStateToProps = (state) => {
    return {
        username: state.user.username,
        password: state.user.password,
        valid: state.userValid.valid,
        queue: state.queue
    }
};
const mapDispatchToProps = {userFetched: userFetched, saveQueue: saveQueue, userValid: userValid}; // (2)

const Dashboard = connect(mapStateToProps, mapDispatchToProps)(_Dashboard); // (3)

export default withStyles(styles)(Dashboard);


